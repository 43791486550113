import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as RealityCheck from '@rushplay/compliance/reality-check'
import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as Session from '@rushplay/session'
import css from '@styled-system/css'
import styled from '@emotion/styled'

import * as Player from './player'
import { Button } from './button'
import { HtmlContent } from './html-content'
import { SelectField } from './select-field'

const dataSchema = {
  type: 'object',
  properties: {
    time: {
      type: 'string',
    },
  },
}

const StyledLabel = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
`

const CheckboxInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`
const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  transition: 0.25s;
  border-radius: 34px;
  ${props =>
    css({
      background: props.checked
        ? 'linear-gradient(#7918dc, #5918dc)'
        : '#2a3149',
    })}

  &:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    ${props =>
      css({
        transform: props.checked ? 'translateX(26px)' : 'translateX(0px)',
      })}
  }
`

function SwitchComponent(props) {
  return (
    <StyledLabel>
      <CheckboxInput
        onChange={props.onChange}
        type="checkbox"
        value={props.value}
      />
      <Slider checked={props.value} />
    </StyledLabel>
  )
}

SwitchComponent.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.bool,
}

export function RealityCheckPage() {
  const dispatch = ReactRedux.useDispatch()
  const currentPeriod = ReactRedux.useSelector(state =>
    RealityCheck.getRealityCheckPeriod(state.realityCheck)
  )

  const values = ReactRedux.useSelector(state =>
    Jurisdiction.getRealityCheckValues(state.jurisdiction)
  )
  const sessionToken = ReactRedux.useSelector(state =>
    Session.getSessionToken(state.session)
  )
  const [enableRealityCheck, setEnableRealityCheck] = React.useState(() =>
    Boolean(currentPeriod)
  )

  const translate = Herz.I18n.useTranslate()

  function handleEnableRealityCheck() {
    setEnableRealityCheck(current => !current)
  }

  return (
    <Common.Box maxWidth="500px">
      <Common.Text
        fontSize={2}
        fontFamily="head"
        color="page-nav-blue"
        textAlign="center"
      >
        <HtmlContent
          html={{
            __html: translate('reality-check.info'),
          }}
        />
      </Common.Text>
      <Common.Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Common.Box
          fontFamily="head"
          color="page-nav-blue"
          fontWeight="bold"
          fontSize={3}
        >
          Enable Reality Check
        </Common.Box>
        <Common.Box>
          <SwitchComponent
            onChange={handleEnableRealityCheck}
            value={enableRealityCheck}
          />
        </Common.Box>
      </Common.Box>
      <RealityCheck.RealityCheckSettings
        values={values}
        sessionToken={sessionToken}
      >
        {settings => (
          <Forms.Provider
            name="reality-check"
            schema={dataSchema}
            onSubmit={(errors, data) => {
              R.isEmpty(errors) &&
                (!enableRealityCheck
                  ? settings.onRemove()
                  : settings.onSet(data.time))
              setTimeout(() => dispatch(Player.fetchPlayerInfo()), 500)
            }}
          >
            <Common.Box p={[0, 1]}>
              <Common.Box maxWidth="330px" margin="auto">
                <Common.Space pt={2} pb={3}>
                  {enableRealityCheck ? (
                    <SelectField
                      scope="#/properties/time"
                      initialValue={
                        currentPeriod
                          ? settings?.options?.find(
                              ({ value }) =>
                                value === `${currentPeriod / 60000} minutes`
                            ).value
                          : R.path(['options', 0, 'value'], settings)
                      }
                      options={R.map(
                        option => ({
                          value: option.value,
                          label: option.labelKey,
                        }),
                        settings.options
                      )}
                    />
                  ) : null}
                </Common.Space>
                <Button variant="primary" stretch type="submit">
                  {translate('reality-check.confirm')}
                </Button>
              </Common.Box>
            </Common.Box>
          </Forms.Provider>
        )}
      </RealityCheck.RealityCheckSettings>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    'reality-check.confirm',
    'reality-check.title',
    'reality-check.info',
    'reality-check.current-limit',
    'reality-check.options.none',
    'reality-check.confirm',
  ],
  RealityCheckPage
)
