export function getValidatorsForTarget(state, props) {
  const limits = state[props.target] || {}

  const values = Object.keys(limits).reduce((acc, key) => {
    const limit = limits[key]
    acc[key] =
      limit.nextValueCents !== null ? limit.nextValueCents : limit.valueCents
    return acc
  }, {})

  const dayLimit = values.day
  const weekLimit = values.week != null ? values.week : Number.MAX_SAFE_INTEGER
  const monthLimit =
    values.month != null ? values.month : Number.MAX_SAFE_INTEGER

  const adjustedWeekLimit = Math.min(weekLimit, monthLimit)
  const adjustedMonthLimit =
    monthLimit !== Number.MAX_SAFE_INTEGER ? monthLimit : null
  const minDayLimit = 0
  const minWeekLimit = dayLimit !== Number.MAX_SAFE_INTEGER ? dayLimit : 0
  const minMonthLimit = Math.max(
    dayLimit !== Number.MAX_SAFE_INTEGER ? dayLimit : 0,
    weekLimit !== Number.MAX_SAFE_INTEGER ? weekLimit : 0
  )

  const maxDayLimit =
    adjustedWeekLimit !== Number.MAX_SAFE_INTEGER ? adjustedWeekLimit : null
  const maxWeekLimit =
    weekLimit !== Number.MAX_SAFE_INTEGER ? adjustedMonthLimit : null
  const maxMonthLimit = Number.MAX_SAFE_INTEGER

  return {
    day: {
      min: minDayLimit,
      max: maxDayLimit,
    },
    week: {
      min: minWeekLimit,
      max: maxWeekLimit,
    },
    month: {
      min: minMonthLimit,
      max: maxMonthLimit,
    },
  }
}
