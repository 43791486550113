import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import * as RealityCheck from '@rushplay/compliance/reality-check'
import * as Herz from '@rushplay/herz'
import * as Forms from '@rushplay/forms'

import Timeout from './timeout'
import { PurchaseLimit, dataSchema } from './purchase-limit'
import { QueryDrawer } from './query-drawer'
import { RealityCheckPage } from './reality-check'
import { useMenuQueries } from './use-menu-queries'
import { useQuery } from './use-query'

const formKeysForPreloading = [
  'deposit-day',
  'deposit-week',
  'deposit-month',
].reduce((acc, curr) => {
  const keys = Forms.findTranslationKeys(
    curr,
    dataSchema({ min: 0, max: 1000000 })
  )

  acc.push(...keys)
  return acc
}, [])

const responsibleGamingTools = {
  limit: 'limit',
  timeout: 'time-out',
  realityCheck: 'reality-check',
}

function renderTool(query) {
  if (query['responsible-gaming'] === responsibleGamingTools.limit) {
    return <PurchaseLimit />
  }

  if (query['responsible-gaming'] === responsibleGamingTools.timeout) {
    return <Timeout />
  }

  if (query['responsible-gaming'] === responsibleGamingTools.realityCheck) {
    return <RealityCheckPage />
  }

  return null
}

function getDrawerTile(query) {
  if (query['responsible-gaming'] === responsibleGamingTools.limit) {
    return 'purchase-limits.title'
  }

  if (query['responsible-gaming'] === responsibleGamingTools.timeout) {
    return 'time-out.title'
  }

  if (query['responsible-gaming'] === responsibleGamingTools.realityCheck) {
    return 'account.nav.reality-check'
  }

  return ''
}

function ResponsibleGamingDrawer() {
  const dispatch = ReactRedux.useDispatch()
  const translate = Herz.I18n.useTranslate()
  const [query] = useQuery()
  const { menuQuery } = useMenuQueries()
  const { authenticated } = Herz.Auth.useSession()
  const history = ReactRouter.useHistory()

  React.useEffect(() => {
    if (authenticated) {
      dispatch(RealityCheck.fetch())
    }
  }, [authenticated])

  if (!authenticated || !query['responsible-gaming']) {
    return null
  }

  return (
    <QueryDrawer
      activeQueryName="responsible-gaming"
      title={translate(getDrawerTile(query))}
      onSecondaryAction={() => history.push(`?${menuQuery}`)}
    >
      {renderTool(query)}
    </QueryDrawer>
  )
}

Herz.I18n.Loader.preload(
  [
    'coins-store.input.usd.label',
    'purchase-limits.info',
    'purchase-limits.day',
    'purchase-limits.week',
    'purchase-limits.month',
    'purchase-limits.new-limit',
    'purchase-limits.limit-duration',
    'purchase-limits.limit-amount',
    'purchase-limits.current-limit',
    'purchase-limits.title',
    'time-out.title',
    'account.nav.reality-check',
    ...formKeysForPreloading,
  ],
  ResponsibleGamingDrawer
)

export default ResponsibleGamingDrawer
