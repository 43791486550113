import * as React from 'react'
import * as ReactRouter from 'react-router-dom'

import * as common from '@rushplay/common'
import * as Herz from '@rushplay/herz'

import * as http from './http'
import { Heading } from './heading'

export function NotFound() {
  http.useResponseStatus(404)
  const translate = Herz.I18n.useTranslate()

  return (
    <common.Flex
      flexGrow="1"
      flexShrink="1"
      flexBasis="0"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Heading level={1} color="g-text">
        404
      </Heading>
      <common.Space p={2}>
        <common.Text fontSize={3}>
          {translate('page-not-found.title')}
        </common.Text>
      </common.Space>

      <common.Box
        fontSize={3}
        textAlign="center"
        style={{ textDecoration: 'underline' }}
      >
        <ReactRouter.Link to="/">
          {translate('page-not-found.link')}
        </ReactRouter.Link>
      </common.Box>
    </common.Flex>
  )
}

Herz.I18n.Loader.preload(
  ['page-not-found.title', 'page-not-found.link'],
  NotFound
)

// for @loadable/components
export default NotFound
