import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import querystring from 'querystring'

import { getSafeUpdateQuery } from './use-safe-update-query'

/**
 * Purpose: To receive and edit query object
 * @returns {[Object, function]}
 * @example
 * const [query, updateQuery] = useQuery()
 */
export function useQuery() {
  const history = ReactRouter.useHistory()
  const location = ReactRouter.useLocation()
  const query = querystring.parse(R.drop(1, location.search))

  const updateQuery = React.useCallback(
    newQuery => history.push(`?${getSafeUpdateQuery(location, newQuery)}`),
    [location, query, history]
  )

  return [query, updateQuery]
}
