import * as React from 'react'
import * as ReactRedux from 'react-redux'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as Limits from '@rushplay/compliance/limits'

import * as Icons from './icons'
import { InputField } from './input-field'
import { SubmitButton } from './submit-button'
import { getValidatorsForTarget } from './get-validators-for-target'

function ProgressBar(props) {
  return (
    <Common.Box
      width="100%"
      border="1px solid #2A3149"
      borderRadius="10px"
      overflow="hidden"
      mb={0}
    >
      <Common.Box
        height="12px"
        width={`${props.progress}%`}
        backgroundColor="#eaeaea"
        borderRadius="10px"
        transition="width 0.5s ease-in-out"
      />
    </Common.Box>
  )
}

ProgressBar.defaultProps = {
  progress: 0,
}

ProgressBar.propTypes = {
  progress: PropTypes.number,
}

const limitsLength = {
  0: 'day',
  1: 'week',
  2: 'month',
}

export function dataSchema(data) {
  return {
    type: 'object',
    properties: {
      amount: {
        type: 'number',
        maximum: data.max,
        minimum: data.min,
      },
    },
    required: ['amount'],
  }
}

function normalizeAmount(value) {
  const amount = Number.parseInt(value, 10)
  if (Number.isNaN(amount)) {
    return 0
  }
  return Math.round(amount * 100)
}

function parseAmount(value) {
  const amount = Math.round(value / 100)
  if (Number.isNaN(amount)) {
    return ''
  }
  return amount
}

const TransactionType = 'deposit'

export function PurchaseLimit() {
  const translate = Herz.I18n.useTranslate()
  const limitsIndices = ReactRedux.useSelector(state =>
    Jurisdiction.getMoneyLimits(state.jurisdiction, {
      type: TransactionType,
    })
  )
  const [day] = limitsIndices
  const [activeLimit, setActiveLimit] = React.useState(day)

  const moneyLimit = ReactRedux.useSelector(state =>
    Jurisdiction.getMoneyLimit(state.jurisdiction, { id: activeLimit })
  )

  const limitValidators = ReactRedux.useSelector(state =>
    getValidatorsForTarget(state.limits, {
      target: moneyLimit?.type,
    })
  )

  const period = limitsLength[activeLimit]

  const validator = limitValidators[period]

  const minLimit = React.useMemo(
    () => Math.max(moneyLimit?.min || 0, validator?.min),
    [moneyLimit?.min, validator?.min]
  )

  const maxLimit = React.useMemo(
    () =>
      Math.min(
        moneyLimit?.max || Number.MAX_SAFE_INTEGER,
        validator?.max || Number.MAX_SAFE_INTEGER
      ),
    [moneyLimit?.max, validator?.max]
  )

  const schema = React.useMemo(
    () => dataSchema({ min: minLimit, max: maxLimit }),
    [minLimit, maxLimit]
  )

  return (
    <Common.Box p={1} color="page-nav-blue" fontFamily="head">
      <Common.Text textAlign="center" fontSize={2}>
        {translate('purchase-limits.info')}
      </Common.Text>
      <Limits.Limit
        mountPoint="limits"
        period={limitsLength[activeLimit] || 'day'}
        target="deposit"
      >
        {limit => {
          return (
            <Common.Box>
              <Common.Box mt={2}>
                {!limit.isUnset ? (
                  <React.Fragment>
                    <Common.Box display="flex" justifyContent="space-between">
                      <Common.Box fontWeight="bold" fontSize="3">
                        {translate('purchase-limits.current-limit', {
                          amount: limit.valueCents / 100,
                        })}
                      </Common.Box>
                      <Common.Box
                        height="1cap"
                        cursor="pointer"
                        onClick={limit.onUnset}
                      >
                        <Icons.Bin />
                      </Common.Box>
                    </Common.Box>
                    {/* TODO: add progress bar with dynamic values once BE part is ready */}
                    {/* <Common.Box my={1}>
                      <Common.Box mb={0} fontSize={2} color="#eaeaea">
                        $100 of ${limit.valueCents / 100}
                      </Common.Box>
                      <ProgressBar progress={50} />
                      <Common.Text fontSize={1} color="#CDCED0">
                        This limit will reset (05/22/24 | 10:27)
                      </Common.Text>
                    </Common.Box> */}
                  </React.Fragment>
                ) : null}
              </Common.Box>
              <Common.Box mt={1}>
                <Common.Box fontWeight="bold" fontSize="3">
                  {translate('purchase-limits.new-limit')}
                </Common.Box>
                <Common.Box mt={1}>
                  <Common.Text color="#eaeaea" fontSize={2}>
                    {translate('purchase-limits.limit-duration')}
                  </Common.Text>
                  <Common.Box
                    display="flex"
                    justifyContent="space-between"
                    pb={1}
                    mt={0}
                  >
                    {limitsIndices.map(limit => {
                      return (
                        <Common.Box
                          key={limitsLength[limit]}
                          width="90px"
                          height="44px"
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          color={
                            activeLimit === limit ? 'white' : 'page-nav-blue'
                          }
                          backgroundColor={
                            activeLimit === limit ? '#1849DC' : '#0C132B'
                          }
                          border="1px solid #2A3149"
                          borderRadius="4px"
                          cursor="pointer"
                          onClick={() => setActiveLimit(limit)}
                        >
                          {translate(`purchase-limits.${limitsLength[limit]}`)}
                        </Common.Box>
                      )
                    })}
                  </Common.Box>
                </Common.Box>
                <Forms.Provider
                  key={`${limit.target}-${limit.period}`}
                  name={`${limit.target}-${limit.period}`}
                  schema={schema}
                  onSubmit={(errors, data) => {
                    return (
                      Object.keys(errors).length === 0 &&
                      limit.onSet(data.amount)
                    )
                  }}
                >
                  <Common.Box mt={1}>
                    <Common.Text color="#eaeaea" fontSize={2}>
                      {translate('purchase-limits.limit-amount')}
                    </Common.Text>
                    <Common.Box mt={0}>
                      <InputField
                        inputMode="decimal"
                        scope="#/properties/amount"
                        normalize={normalizeAmount}
                        parse={parseAmount}
                        currency="USD"
                        initialValue={
                          limit.valueCents !== undefined && limit.valueCents
                        }
                        disabled={!limit.isUnset}
                        limitInput
                      />
                    </Common.Box>
                    <Common.Box mt={2}>
                      <SubmitButton stretch disabled={!limit.isUnset} />
                    </Common.Box>
                  </Common.Box>
                </Forms.Provider>
              </Common.Box>
            </Common.Box>
          )
        }}
      </Limits.Limit>
    </Common.Box>
  )
}
