import * as R from 'ramda'
import * as recompose from 'recompose'
import * as ReactRedux from 'react-redux'
import * as React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'

import * as Common from '@rushplay/common'
import * as exclusion from '@rushplay/compliance/exclusion'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Jurisdiction from '@rushplay/compliance/jurisdiction'
import * as Notifications from '@rushplay/notifications'

import * as Icons from './icons'
import * as Constants from './constants'
import * as Player from './player'
import * as Theming from './theming'
import { Button } from './button'
import { Heading } from './heading'
import { HtmlContent } from './html-content'
import { InputPasswordField } from './input-password-field'
import { NotFound } from './not-found'
import { SelectField } from './select-field'
import { SubmitButton } from './submit-button'

const defaultData = {
  isThirdPartySignUp: false,
}

function dataSchema(data = defaultData) {
  return {
    type: 'object',
    properties: {
      password: {
        type: 'string',
      },
      period: {
        type: 'string',
      },
    },
    required: data.isThirdPartySignUp ? ['period'] : ['password', 'period'],
  }
}

const TimeoutProvider = R.compose(
  recompose.setPropTypes({
    children: PropTypes.node,
    values: PropTypes.array,
    onSuccess: PropTypes.func,
    onFailure: PropTypes.func,
  }),
  withRouter,
  ReactRedux.connect(
    state => ({
      values: Jurisdiction.getTimeOutValues(state.jurisdiction),
    }),
    (dispatch, props) => ({
      onFailure: res =>
        Notifications.add({
          message: `errors.time-out.${res.value.message || 'general.unknown'}`,
          level: 'error',
        }),
      onSuccess: () => props.history.push('/logout'),
    })
  ),
  recompose.branch(
    props => R.isEmpty(props.options),
    recompose.renderComponent(NotFound)
  )
)(exclusion.Timeout)

function TimeoutForm(props) {
  const translate = Herz.I18n.useTranslate()
  const [step, setStep] = React.useState(Constants.TimeoutSteps.Initial)
  const periodField = Forms.useField('#/properties/period')
  const form = Forms.useFormContext()
  const errors = Forms.getFormErrors(form.state)

  return (
    <React.Fragment>
      {step === Constants.TimeoutSteps.Initial && (
        <React.Fragment>
          <Common.Text
            fontSize={2}
            textAlign="center"
            color="page-nav-blue"
            fontFamily="head"
          >
            <HtmlContent
              html={{
                __html: translate('time-out-period.description', {}),
              }}
            />
          </Common.Text>

          <Common.Box mx="auto" maxWidth="280px">
            <Theming.Alternative>
              <SelectField
                initialValue={R.head(props.options).value}
                options={R.map(
                  option => R.assoc('label', option.labelKey, option),
                  props.options
                )}
                scope="#/properties/period"
                data-testid="time-out.input-period"
              />
            </Theming.Alternative>
            <Common.Box pt={3} pb={1} display="flex" justifyContent="center">
              <Button
                stretch
                variant="primary"
                onClick={() => setStep(Constants.TimeoutSteps.Confirmation)}
                data-testid="time-out.button-confirm"
              >
                {translate('time-out-period.confirm')}
              </Button>
            </Common.Box>
          </Common.Box>
        </React.Fragment>
      )}
      {step === Constants.TimeoutSteps.Confirmation && (
        <React.Fragment>
          <Heading
            level={3}
            color="page-nav-blue"
            textAlign="center"
            data-testid="time-out.confirm-title"
          >
            {translate('time-out-period.confirmation.title')}
          </Heading>
          <Common.Text fontSize={2} color="page-nav-blue" textAlign="center">
            <HtmlContent
              html={{
                __html: translate('time-out-period.confirmation.content', {
                  period: periodField.value,
                }),
              }}
            />
          </Common.Text>
          {!props.isThirdPartySignUp && (
            <React.Fragment>
              <Common.Text
                fontSize={2}
                color="page-nav-blue"
                textAlign="center"
              >
                <HtmlContent
                  html={{
                    __html: translate('time-out.password-confirmation.content'),
                  }}
                />
              </Common.Text>
              <Common.Box pt={1} mx="auto" maxWidth="280px">
                <Theming.Alternative>
                  <InputPasswordField
                    prependIcon={<Icons.Lock />}
                    scope="#/properties/password"
                    suppressVisualFeedback={R.isEmpty(errors)}
                    data-testid="time-out.input-password"
                  />
                </Theming.Alternative>
              </Common.Box>
            </React.Fragment>
          )}
          <Common.Box
            display="flex"
            justifyContent="space-around"
            pt={3}
            maxWidth="280px"
            mx="auto"
          >
            <Button
              variant="secondary-outlined"
              onClick={() => setStep(Constants.TimeoutSteps.Initial)}
              data-testid="timeout-confirmation-cancel"
            >
              {translate('time-out-period.confirmation.cancel')}
            </Button>
            <SubmitButton
              variant="secondary-outlined"
              data-testid="timeout-confirmation-submit"
            />
          </Common.Box>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

Herz.I18n.Loader.preload(
  [
    'time-out-period.title',
    'time-out-period.description',
    'time-out.period.label',
    'time-out-period.confirm',
    'time-out-period.confirmation.title',
    'time-out-period.confirmation.content',
    'time-out-period.confirmation.cancel',
    'time-out.password-confirmation.content',
  ],
  TimeoutForm
)

TimeoutForm.propTypes = {
  isThirdPartySignUp: PropTypes.bool,
  options: PropTypes.array,
}

const formKeysForPreloading = Forms.findTranslationKeys(
  'time-out',
  dataSchema(),
  ['label', 'placeholder']
)

function Timeout() {
  const isThirdPartySignUp = ReactRedux.useSelector(state =>
    Player.getThirdPartySignUp(state.player)
  )

  const schema = React.useMemo(() => dataSchema({ isThirdPartySignUp }), [
    isThirdPartySignUp,
  ])

  return (
    <Common.Box width="100%" p={0}>
      <TimeoutProvider>
        {timeout => (
          <Forms.Provider
            name="time-out"
            schema={schema}
            onSubmit={(errors, data) => {
              if (R.isEmpty(errors)) {
                if (!isThirdPartySignUp) {
                  return timeout.onSubmit(data.period, data.password)
                }
                return timeout.onSubmit(data.period)
              }
            }}
          >
            <TimeoutForm
              isThirdPartySignUp={isThirdPartySignUp}
              options={timeout.options}
            />
          </Forms.Provider>
        )}
      </TimeoutProvider>
    </Common.Box>
  )
}

Herz.I18n.Loader.preload([...formKeysForPreloading], Timeout)

export default Timeout
